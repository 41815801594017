<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <strong>Topic</strong>
          </CCardHeader>
          <CRow>
            <CCol md="8">
              <CCardBody>
                <CForm>
                  <CInput
                    label="Topic *"
                    v-model="topic.topicName"
                    horizontal
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the Topic'"
                    @blur="$v.topic.topicName.$touch()"
                    :is-valid="
                      $v.topic.topicName.$dirty
                        ? !$v.topic.topicName.$error
                        : null
                    "
                  >
                  </CInput>
                  <CTextarea
                    label="Control Measures *"
                    v-model="topic.controlMeasures"
                    horizontal
                    rows="6"
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the Text'"
                    @blur="$v.topic.controlMeasures.$touch()"
                    :is-valid="
                      $v.topic.controlMeasures.$dirty
                        ? !$v.topic.controlMeasures.$error
                        : null
                    "
                  />

                  <!-- <p>Using mustaches: {{ rawHtml }}</p>
<p>Using v-html directive: <span v-html="rawHtml"></span></p> -->
                </CForm>
              </CCardBody>
            </CCol>
          </CRow>

          <CCardFooter align="right">
            <CButton
              v-if="mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="primary"
              @click="editMode()"
            >
              <CIcon name="cil-pencil" /> Edit
            </CButton>

            <CButton
              v-if="!mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="success"
              @click="save()"
            >
              <CIcon name="cil-check-circle" /> Save
            </CButton>

            <CButton
              class="mr-2"
              type="Cancel"
              size="sm"
              color="danger"
              @click="cancel()"
            >
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import htmlToFormattedText from "html-to-formatted-text";
import {
  required,
  minLength,
  maxLength,
  url,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  name: "Topic",
  data() {
    return {
      mode: {
        newMode: false,
        editMode: false,
        viewMode: false,
      },
      // topicId:"",
      topic: {
        topicId: "0",
        topicName: "",
        controlMeasures: "",
        briefId: this.$route.params.briefId,
      },

      horizontal: { label: "col-3", input: "col-9" },
    };
  },

  validations: {
    topic: {
      topicName: {
        required,
      },
      controlMeasures: {
        required,
      },
    },
  },
  created() {
    if (this.$route.params.mode == "new") {
      this.mode.newMode = true;
      this.mode.viewMode = false;
      this.mode.editMode = false;
      this.briefId = this.$route.params.briefId;
    }
    if (this.$route.params.mode == "view") {
      this.mode.viewMode = true;
      this.briefId = this.$route.params.briefId;
      this.topicId = this.$route.params.topicId;
      this.getUser();
    }
    if (this.$route.params.mode == "edit") {
      this.mode.editMode = true;
      this.briefId = this.$route.params.briefId;
      this.topicId = this.$route.params.topicId;
      this.getUser();
    }
  },
  methods: {
    editMode() {
      this.mode.editMode = true;
      this.mode.viewMode = false;
    },
    cancel() {
      window.history.back();
    },
    save() {
      this.$v.topic.$touch();

      let controlText = htmlToFormattedText(this.topic.controlMeasures);

      this.topic.controlMeasures = controlText;
      if (this.$v.topic.$invalid) {
        console.log("form is invalid", JSON.stringify(this.$v.topic));
      } else {
        this.apiPostSecure(
          process.env.VUE_APP_API_HOST + "/emapp/web/secure/save/topic",
          this.topic
        ).then((post) => {
          window.history.back();
        });
      }
    },
    validator(val) {
      return val ? val.length >= 4 : false;
    },

    getUser() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/topic/" +
          this.topicId
      )
        .then((response) => response.json())
        .then((data) => (this.topic = data));
    },
  },
  mounted() {},
};
</script>
